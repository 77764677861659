/**
    * Sliders
    * @class
    */
// import { mqTouch } from '../helpers/tokens';
import Swiper, { Navigation } from 'swiper';
Swiper.use([ Navigation ]);
// import Swiper, { SwiperPluginNavigation } from 'tiny-swiper';
// Swiper.use([ SwiperPluginNavigation ]);



class Sliders {

  /**
   * @method
   * @description Inits the module with necessary functions
  **/
  constructor() {
    this.elts = {
      sliders: document.querySelectorAll( '[data-slider]' )
    };

    this._init();
    this._bind();
  }

  /**
   * @method
   * @description Bind triggers & events
  **/
  _bind() {
  }

  /**
   * @method
   * @description Init elements
  **/
  _init() {
    for ( const slider of this.elts.sliders ) {
      slider.slider = new Swiper( slider, {
        navigation: {
          nextEl: '[data-next]',
          prevEl: '[data-prev]',
        },
      });
    }
  }

}

export default new Sliders;
