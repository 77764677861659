/**
 * Toggle
 * @class
 */
class Toggle {

  /**
   * @method
   * @description Inits the module with necessary functions
  **/
  constructor() {

    this.elts = {
      togglers: document.querySelector( '[data-toggle]' )
    };

    if ( !this.elts.togglers ) {
      return;
    }

    this._bind();

    // this.toggledConditions(); // called by misc resize
  }

  /**
   * @method
   * @description Bind triggers & events
  **/
  _bind() {
    document.body.addEventListener( 'click', e => {
      const trigger = e.target.closest( '[data-toggle]' );

      if ( trigger ) {
        this._toggleHandler( trigger );
      }
    });
  }

  _toggleHandler( trigger ) {

    const id = trigger.getAttribute( 'aria-controls' ),
      open = trigger.getAttribute( 'aria-expanded' ) == 'true',
      target = document.getElementById( id ),
      triggers = document.querySelectorAll( `[aria-controls=${id}]` );

    let data;

    for ( const trigger of triggers ) {
      trigger.setAttribute( 'aria-expanded', !open );
    }
    target.setAttribute( 'aria-hidden', open );

    try {
      data = JSON.parse( trigger.dataset.toggle );
    }
    // eslint-disable-next-line no-empty
    catch ( e ) {};

    if ( data && data.html ) {
      if ( Array.isArray( data.html )) {
        data.html.forEach( className => {
          document.documentElement.classList[ open ? 'remove' : 'add' ]( className );
        });
      }
      else {
        document.documentElement.classList[ open ? 'remove' : 'add' ]( data.html );
      }

      if ( open ) {
        this._enableScroll();
      }
      else {
        this._preventScroll();
        console.log( 'aze' );
      }

      if ( data && data.clickOut ) {
        document.body.addEventListener( 'click', this._clickOut.bind( this ));
        window.currentToggle = Object.assign({ elt: trigger, id: trigger.getAttribute( 'aria-controls' ) }, data );
      }
    }

  }

  /**
   * @method
   * @description Set aria-hidden conditionally
  **/
  // toggledConditions() {

  //   for ( const conditionalToggled of document.querySelectorAll( '[data-allyhide]' )) {
  //     let condition = conditionalToggled.dataset.allyhide;
  //     const translatedCondition = `(${window.config.conditions[ condition[ 0 ] ]}: ${condition.substr( 1 )})`;

  //     conditionalToggled.setAttribute( 'aria-hidden', window.matchMedia( translatedCondition ).matches );
  //   }

  // }

  /**
   * @description Close current layer
   * @method
   * @param Object
  **/
  _clickOut( e ) {

    const trigger = e.target,
      parentTrigger = trigger.closest( '[aria-controls]' ),
      parentTriggerIsTrigger = parentTrigger && parentTrigger.getAttribute( 'aria-controls' ) == window.currentToggle.id,
      parentHideable = trigger.closest( '[aria-hidden][id]' ),
      parentHideableIsCurrentZone = parentHideable && parentHideable.id == window.currentToggle.id && trigger.id.indexOf( 'popin' ) == -1;

    if (( parentTrigger && parentTriggerIsTrigger ) || ( parentHideable && parentHideableIsCurrentZone )) {
      return;
    }

    document.body.removeEventListener( 'click', this._clickOut );
    this._toggleHandler( window.currentToggle.elt );

  }

  /**
   * @method
   * @description Prevents background scrolling
  **/
  _preventScroll() {
    const scrollRoot = document.body,
      scrollY = scrollRoot.scrollTop || window.scrollY;

    window.requestAnimationFrame(() => {
      scrollRoot.style.position = 'fixed';
      scrollRoot.style.overflow = 'visible';
      scrollRoot.style.top = `-${scrollY}px`;
      scrollRoot.style.width = '100%';
    });
  }

  /**
   * @method
   * @description Enable background scrolling
  **/
  _enableScroll() {
    const scrollRoot = document.body,
      scrollY = scrollRoot.style.top + 1;

    document.documentElement.style.scrollBehavior = 'initial';
    window.repositioning = true;

    window.requestAnimationFrame(() => {
      scrollRoot.style.position = '';
      scrollRoot.style.overflow = '';
      scrollRoot.style.top = '';
      scrollRoot.style.width = '';
      window.scrollTo( 0, parseInt( scrollY || '0' ) * -1 );
      window.requestAnimationFrame(() => {
        document.documentElement.style.scrollBehavior = '';
        window.setTimeout(() => {
          window.repositioning = false;
        }, 100 );
      });
    });

  }

}

export default new Toggle;
