/**
 * Misc
 * @class
 */
// import smoothscroll from 'smoothscroll-polyfill';

import throttle from '../helpers/throttle';
import { headerHeight } from '../helpers/tokens';

class Misc {

  /**
    * @method
    * @description Inits the module with necessary functions
   **/
  constructor() {

    this.elts = {
      putMeToTopBt: document.querySelectorAll( '[data-to-top]' )
    };

    this._bind();

    this.appHeight( '--appHeight' );
    this.resizeHandler();
  }

  /**
    * @method
    * @description Bind triggers & events
   **/
  _bind() {

    window.addEventListener( 'resize', throttle( this.resizeHandler.bind( this ), 100 ));
    window.addEventListener( 'scroll', throttle( this._scroll.bind( this ), 50 ));

    for ( const video of document.querySelectorAll( 'video' )) {
      video.addEventListener( 'play', this._videoPlay.bind( this ));
      video.addEventListener( 'ended', this._videoPause.bind( this ));
    }

    if ( this.elts.putMeToTopBt ) {
      // if ( getComputedStyle( document.documentElement ).scrollBehavior !== 'smooth' ) {
      //   smoothscroll.polyfill();
      // }
      document.addEventListener( 'click', this._scrollMeToTop );
    }

  }

  /**
    * @method
    * @description Calculate viewport height
   **/
  appHeight( customProp ) {
    document.documentElement.style.setProperty( customProp, `${window.innerHeight}px` );
  }

  /**
    * @method
    * @description Scroll to top behavior
   **/
  _scrollMeToTop( e ) {
    const bt = e.target.closest( '[data-to-top]' );

    if ( !bt || ( bt && bt.getAttribute( 'aria-expanded' ) == 'false' )) {
      return;
    }

  }

  /**
    * @method
    * @description Resize handler
   **/
  resizeHandler() {
    document.documentElement.classList.add( 'no-transitions' );
    // this.appHeight( window.matchMedia( mqTouch ).matches ? '--respAppHeight' : '--appHeight' );
    this.appHeight( '--appHeight' );
    window.requestAnimationFrame(() => {
      document.documentElement.classList.remove( 'no-transitions' );
    });
  }

  /**
    * @method
    * @description Scroll event listener
   **/
  _scroll( e ) {
    const scrollPos = e.target.scrollTop || window.scrollY;

    if ( !window.repositioning ) {
      document.documentElement.classList[ this.lastPos > scrollPos ? 'add' : 'remove' ]( 'to-top' );
    }
    this.lastPos = scrollPos;

    document.documentElement.classList[ scrollPos > 0 ? 'add' : 'remove' ]( 'scrolled' );
  }

  /**
    * @method
    * @description Video play/pause handling
   **/
  _videoPlay( e ) {
    const video = e.currentTarget;

    if ( window.currentVideo && window.currentVideo != video ) {
      window.currentVideo.pause();
    }
    window.currentVideo = video;
    this._videoObserver( video );
  }
  _videoPause( e ) {
    const video = e.currentTarget;

    // put poster back
    video.load();
  }

  /**
    * @method
    * @description Video play/pause button handling
   **/
  _videoController( e ) {
    if ( !e.target.closest( '[data-play]' )) {
      return;
    }
    const bt = e.target,
      video = document.getElementById( bt.getAttribute( 'aria-controls' )),
      playing = !video.paused;

    if ( !playing ) {
      video.play();
    }
    else {
      video.pause();
    }
  }

  /**
    * @method
    * @description Pause video if out of viewport
   **/
  _videoObserver( video ) {

    const observer = new IntersectionObserver(( entries ) => {
      entries.forEach( entry => {
        if ( entry.intersectionRatio < 0.5 ) {
          video.pause();
        }
        else {
          video.play();
        }

      });
    }, { threshold: .5 });

    observer.observe( video );
  }

}

export default new Misc;

